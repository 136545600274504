<template>
    <v-container>
        <v-row>
            <v-col
                cols="12"
            >
                <base-card

                    title="Distributed Columns"
                >
                    <v-card-title>
                        {{ $t('statistic.detailed.votesAndPoints') }}
                    </v-card-title>
                    <v-card-subtitle class="ml-3">
                        <div> {{ $t('statistic.detailed.totalPoints', {points: getTotal.points}) }} </div>
                        <div> {{ $t('statistic.detailed.totalVotes', {votes: getTotal.votes}) }} </div>
                    </v-card-subtitle>

                    <div
                        id="basicArea-chart"
                        style="min-height: 365px"
                    >
                        <apexchart
                            type="bar"
                            height="350"
                            :options="getDistributedColumnChart.chartOptions"
                            :series="getDistributedColumnChart.chartOptions.series"
                        />
                    </div>
                </base-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'StatisticsDetails',
    data() {
        return {
            loading: true,
            distributedColumnChart: {
                chartOptions: {
                    chart: {
                        toolbar: {
                            show: false
                        },
                        stacked: false
                    },
                    stroke: {
                        width: [0, 2],
                        curve: 'smooth'
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '60%'
                        }
                    },

                    fill: {
                        opacity: [1, 0.45],
                        gradient: {
                            inverseColors: false,
                            shade: 'light',
                            type: 'vertical',
                            opacityFrom: 0.85,
                            opacityTo: 0.55,
                            stops: [0, 100, 100, 100]
                        }
                    },
                    labels: [],
                    markers: {
                        size: 16
                    },
                    xaxis: {
                        categories: [],
                        labels: {
                            style: {
                                colors: ['#CD7F32', '#C0C0C0', '#D4AF37', '#A5A4A2'],
                                fontSize: '16px'
                            }
                        }
                    },
                    /* tooltip: {
                        y: {
                            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                                return value + ''
                            }
                        }
                    }, */
                    yaxis: {
                        title: {
                            text: 'Cantidad'
                        },
                        min: 0
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            formatter: function (y) {
                                if (typeof y !== 'undefined') {
                                    return y.toFixed(0)
                                }
                                return y
                            }
                        }
                    }
                }
            }
        }
    },
    created() {
        this.$store.dispatch('statistics/loadMyStatisticsDetailed');
    },
    mounted() {

    },
    computed: {
        ...mapGetters('statistics', ['getLikes']),
        getDistributedColumnChart() {
            const distributedColumnChart = this.distributedColumnChart;
            const seriesQty = [];
            const seriesPoints = [];
            const categories = [];
            for (const key in this.getLikes) {
                const elem = this.getLikes[key];
                seriesQty.push(elem.count);
                seriesPoints.push(elem.count * key);
                categories.push(elem.label);
            }

            if (seriesQty.length && categories.length) {
                distributedColumnChart.chartOptions.xaxis.categories = categories;
                distributedColumnChart.chartOptions.series = [
                    {
                        data: seriesQty,
                        name: 'Cantidad',
                        type: 'column'
                    },
                    {
                        data: seriesPoints,
                        name: 'Puntos',
                        type: 'area'
                    }
                ];
            }

            return distributedColumnChart;
        },
        getTotal() {
            let votes = 0;
            let points = 0;
            for (const key in this.getLikes) {
                const elem = this.getLikes[key];
                votes += elem.count;
                points += elem.count * key;
            }

            return { votes, points };
        }
    },
    methods: {

    }
}
</script>

<style scoped>

</style>
